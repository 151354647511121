import { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiEye, mdiContentCopy } from "@mdi/js";
import { CommonLoading } from "../../../components/Loading/Loading";
import { TbTTab, TbTTabView } from "../../../components/TabView/TabView";
import TbTPage from "../../../components/TbTPage/TbTPage";
import {
    BoutSide,
    Division,
    DualMeetSignatureKey,
    IBoutEvent,
    ICollegeEvent,
    IDualMeet,
    IDualMeetBout,
    IDualMeetTeam,
    IDualMeet_DB,
    IExistingFencer,
    IWriteInFencer,
    Lineup,
    LineupFencer,
    UserFlag,
    Weapon
} from "../../../types";
import TextField from "@mui/material/TextField";

import "./DualMeetInfo.css";
import {
    boutFencerName,
    boutWinner,
    dualMeetUnlocked,
    getIndividualStatsForBouts,
    getIndividualStatsForMeet,
    meetScoreFromBouts,
    weaponToSignatureKey
} from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Button,
    Stack,
    Modal,
    Box,
    Link,
    MenuItem,
    Select,
    IconButton,
    FormControl,
    InputLabel,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemButton,
    ListItemText,
    Tabs,
    Tab,
    Alert,
    DialogContentText
} from "@mui/material";
import { useWindowSize } from "../../../hooks/window";
import { modalBoxStyle, modalBoxStyleNarrower } from "../../..";
import useDatabase from "../../../hooks/database";
import StyledDropdown from "../../../components/StyledDropdown/StyledDropdown";
import ErrorPage from "../NotFound/NotFound";
import { exportCollegeDualMeet } from "../../../utils/pdfExport";
import { MedicalIcon } from "../../../components/MedicalIcon";
import { MedicalDialog } from "../../../components/MedicalDialog";
import BoutFencerSection from "../../../components/BoutFencerSection/BoutFencerSection";
import { DBResult, isSuccess } from "../../../utils/database";
import { ERROR_DUAL_MEET_DOES_NOT_EXIST } from "../../../utils/constants";
import BoutLog from "../../../components/BoutLog/BoutLog";
import DualMeetSummary from "../../../components/DualMeetSummary/DualMeetSummary";
import { DateTimePicker } from "@mui/x-date-pickers";
import ScoringLockedDialog from "../../../components/DualMeetInfo/ScoringLocked";
import SnackbarError from "../../../components/SnackbarError";
import BoutViewerSettingsDialog from "../../../components/DualMeetInfo/BoutViewerSettingsDialog";
import BoutsList from "../../../components/DualMeetInfo/BoutsList";
import LiveNowBout from "../../../components/DualMeetInfo/LiveNowBout";
import LocalSettings from "../../../utils/localSettings";

interface LineupSelectProps {
    label: string;
    value: string;
    fencers: (IExistingFencer | IWriteInFencer)[];
    teamName: string;
    teamAbbreviation: string;
    setFencer: (v: LineupFencer) => void;
    startWriteIn: () => void;
}

const LineupSelect = ({ label, value, fencers, teamName, teamAbbreviation, setFencer, startWriteIn }: LineupSelectProps) => {
    const processFencer = (fencer: IExistingFencer | IWriteInFencer | "forfeit") => {
        if (fencer === "forfeit") return setFencer({ type: "forfeit" });

        if ("createdBy" in fencer) {
            setFencer({
                firstName: fencer.firstName,
                lastName: fencer.lastName,
                teamName,
                teamAbbreviation,
                gradYear: fencer.gradYear,
                id: fencer.id
            });
        } else {
            setFencer({
                firstName: fencer.firstName,
                lastName: fencer.lastName,
                teamName,
                teamAbbreviation
            });
        }
    };

    return (
        <FormControl sx={{ margin: "10px" }}>
            <InputLabel id="yk-label">{label}</InputLabel>
            <Select labelId="yk-label" label={label} value={value} sx={{ width: "200px" }}>
                {fencers.map(fencer => (
                    <MenuItem
                        key={`${label}${fencer.firstName}${fencer.lastName}`}
                        value={`${fencer.firstName}${fencer.lastName}`}
                        onClick={() => processFencer(fencer)}
                    >
                        {fencer.firstName} {fencer.lastName}
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem value="writeIn" onClick={startWriteIn}>
                    Write-in
                </MenuItem>
                <MenuItem value="forfeit" onClick={() => processFencer("forfeit")}>
                    Forfeit
                </MenuItem>
            </Select>
        </FormControl>
    );
};

interface LineupEditorProps {
    meetId: string;
    fencers: Record<Weapon, Map<IExistingFencer | IWriteInFencer, number>>;
    teamName: string;
    teamAbbreviation: string;
    home: boolean;
    sabreVisible: boolean;
    foilVisible: boolean;
    epeeVisible: boolean;
    setFencers: React.Dispatch<React.SetStateAction<Record<Weapon, Map<IExistingFencer | IWriteInFencer, number>>>>;
    close: () => void;
}

type LineupSlot = "sabre1" | "foil1" | "epee1" | "sabre2" | "foil2" | "epee2" | "sabre3" | "foil3" | "epee3";

const LineupEditor = ({
    meetId,
    fencers,
    home,
    teamName,
    teamAbbreviation,
    sabreVisible,
    foilVisible,
    epeeVisible,
    setFencers,
    close
}: LineupEditorProps) => {
    const DB = useDatabase();

    const [writeInOpen, setWriteInOpen] = useState<LineupSlot | null>(null);
    const [writeInFirstName, setWriteInFirstName] = useState("");
    const [writeInLastName, setWriteInLastName] = useState("");

    const [sabre1, setSabre1] = useState<LineupFencer | null>(null);
    const [sabre2, setSabre2] = useState<LineupFencer | null>(null);
    const [sabre3, setSabre3] = useState<LineupFencer | null>(null);
    const [foil1, setFoil1] = useState<LineupFencer | null>(null);
    const [foil2, setFoil2] = useState<LineupFencer | null>(null);
    const [foil3, setFoil3] = useState<LineupFencer | null>(null);
    const [epee1, setEpee1] = useState<LineupFencer | null>(null);
    const [epee2, setEpee2] = useState<LineupFencer | null>(null);
    const [epee3, setEpee3] = useState<LineupFencer | null>(null);

    const setterObj: Record<LineupSlot, React.Dispatch<React.SetStateAction<LineupFencer | null>>> = {
        sabre1: setSabre1,
        sabre2: setSabre2,
        sabre3: setSabre3,
        foil1: setFoil1,
        foil2: setFoil2,
        foil3: setFoil3,
        epee1: setEpee1,
        epee2: setEpee2,
        epee3: setEpee3
    };

    const submitWriteInFencer = () => {
        if (writeInOpen) {
            const newFencer = {
                firstName: writeInFirstName,
                lastName: writeInLastName,
                teamName,
                teamAbbreviation
            };
            let weapon: Weapon | "" = "";
            if (writeInOpen === "sabre1" || writeInOpen === "sabre2" || writeInOpen === "sabre3") weapon = "Sabre";
            if (writeInOpen === "foil1" || writeInOpen === "foil2" || writeInOpen === "foil3") weapon = "Foil";
            if (writeInOpen === "epee1" || writeInOpen === "epee2" || writeInOpen === "epee3") weapon = "Epee";
            if (!weapon) return;
            fencers[weapon].set(newFencer, 0);
            setterObj[writeInOpen](newFencer);
            setWriteInFirstName("");
            setWriteInLastName("");
            setWriteInOpen(null);
        }
    };

    const update = (func: React.Dispatch<React.SetStateAction<LineupFencer | null>>) => (val: LineupFencer | null) => {
        const checkVal = (fencer: LineupFencer | null): boolean => {
            if (fencer === null || val === null) return false;
            if ("type" in fencer || "type" in val) return false;
            if (fencer?.firstName !== val?.firstName) return false;
            if (fencer?.lastName !== val?.lastName) return false;
            return true;
        };

        if (checkVal(sabre1)) setSabre1(null);
        if (checkVal(sabre2)) setSabre2(null);
        if (checkVal(sabre3)) setSabre3(null);
        if (checkVal(foil1)) setFoil1(null);
        if (checkVal(foil2)) setFoil2(null);
        if (checkVal(foil3)) setFoil3(null);
        if (checkVal(epee1)) setEpee1(null);
        if (checkVal(epee2)) setEpee2(null);
        if (checkVal(epee3)) setEpee3(null);

        func(val);
    };

    const l1 = home ? 3 : 6;
    const l2 = home ? 1 : 5;
    const l3 = home ? 2 : 4;

    const submitWeapon = (weapon: Weapon) => {
        const obj: Lineup = {
            Sabre: { [l1]: sabre1, [l2]: sabre2, [l3]: sabre3 },
            Foil: { [l1]: foil1, [l2]: foil2, [l3]: foil3 },
            Epee: { [l1]: epee1, [l2]: epee2, [l3]: epee3 }
        };

        DB.addLineupToMeet(meetId, home ? 1 : 2, weapon, obj).then(close);
    };

    const sabreFencers = [...fencers.Sabre.keys()];
    const foilFencers = [...fencers.Foil.keys()];
    const epeeFencers = [...fencers.Epee.keys()];

    const genValue = (fencer: LineupFencer | null) => {
        if (!fencer) return "";
        if ("type" in fencer) return "forfeit";
        return `${fencer?.firstName || ""}${fencer?.lastName || ""}`;
    };

    return (
        <Box sx={modalBoxStyle}>
            <Typography variant="h4" fontFamily="Lexend Deca">
                Lineup editor
            </Typography>
            <Typography variant="body1" sx={{ margin: "10px 0" }}>
                Set the lineup for your team.
                {home ? " 1, 2, and 3" : " 4, 5, and 6"} designations are based on the positions in the NCAA scoresheet. This will become
                visible when both teams have submitted their lineup. Once this is submitted, it cannot be changed.
            </Typography>
            {sabreVisible && (
                <>
                    <Typography variant="h6" fontFamily="Lexend Deca">
                        Sabre
                    </Typography>
                    <Box className="lineupSection">
                        <LineupSelect
                            label={`Sabre ${l1}`}
                            fencers={sabreFencers}
                            value={`${genValue(sabre1)}`}
                            setFencer={update(setSabre1)}
                            startWriteIn={() => setWriteInOpen("sabre1")}
                            teamName={teamName}
                            teamAbbreviation={teamAbbreviation}
                        />
                        <LineupSelect
                            label={`Sabre ${l2}`}
                            fencers={sabreFencers}
                            value={`${genValue(sabre2)}`}
                            setFencer={update(setSabre2)}
                            startWriteIn={() => setWriteInOpen("sabre2")}
                            teamName={teamName}
                            teamAbbreviation={teamAbbreviation}
                        />
                        <LineupSelect
                            label={`Sabre ${l3}`}
                            fencers={sabreFencers}
                            value={`${genValue(sabre3)}`}
                            setFencer={update(setSabre3)}
                            startWriteIn={() => setWriteInOpen("sabre3")}
                            teamName={teamName}
                            teamAbbreviation={teamAbbreviation}
                        />
                    </Box>
                    <Button variant="contained" onClick={() => submitWeapon("Sabre")} sx={{ marginLeft: "10px" }}>
                        Submit Sabre Lineup
                    </Button>
                </>
            )}
            {foilVisible && (
                <>
                    <Typography variant="h6" fontFamily="Lexend Deca">
                        Foil
                    </Typography>
                    <Box className="lineupSection">
                        <LineupSelect
                            label={`Foil ${l1}`}
                            fencers={foilFencers}
                            value={`${genValue(foil1)}`}
                            setFencer={update(setFoil1)}
                            startWriteIn={() => setWriteInOpen("foil1")}
                            teamName={teamName}
                            teamAbbreviation={teamAbbreviation}
                        />
                        <LineupSelect
                            label={`Foil ${l2}`}
                            fencers={foilFencers}
                            value={`${genValue(foil2)}`}
                            setFencer={update(setFoil2)}
                            startWriteIn={() => setWriteInOpen("foil2")}
                            teamName={teamName}
                            teamAbbreviation={teamAbbreviation}
                        />
                        <LineupSelect
                            label={`Foil ${l3}`}
                            fencers={foilFencers}
                            value={`${genValue(foil3)}`}
                            setFencer={update(setFoil3)}
                            startWriteIn={() => setWriteInOpen("foil3")}
                            teamName={teamName}
                            teamAbbreviation={teamAbbreviation}
                        />
                    </Box>
                    <Button variant="contained" onClick={() => submitWeapon("Foil")} sx={{ marginLeft: "10px" }}>
                        Submit Foil Lineup
                    </Button>
                </>
            )}
            {epeeVisible && (
                <>
                    <Typography variant="h6" fontFamily="Lexend Deca">
                        Epee
                    </Typography>
                    <Box className="lineupSection">
                        <LineupSelect
                            label={`Epee ${l1}`}
                            fencers={epeeFencers}
                            value={`${genValue(epee1)}`}
                            setFencer={update(setEpee1)}
                            startWriteIn={() => setWriteInOpen("epee1")}
                            teamName={teamName}
                            teamAbbreviation={teamAbbreviation}
                        />
                        <LineupSelect
                            label={`Epee ${l2}`}
                            fencers={epeeFencers}
                            value={`${genValue(epee2)}`}
                            setFencer={update(setEpee2)}
                            startWriteIn={() => setWriteInOpen("epee2")}
                            teamName={teamName}
                            teamAbbreviation={teamAbbreviation}
                        />
                        <LineupSelect
                            label={`Epee ${l3}`}
                            fencers={epeeFencers}
                            value={`${genValue(epee3)}`}
                            setFencer={update(setEpee3)}
                            startWriteIn={() => setWriteInOpen("epee3")}
                            teamName={teamName}
                            teamAbbreviation={teamAbbreviation}
                        />
                    </Box>
                    <Button variant="contained" onClick={() => submitWeapon("Epee")} sx={{ marginLeft: "10px" }}>
                        Submit Epee Lineup
                    </Button>
                </>
            )}
            <Modal open={Boolean(writeInOpen)} onClose={() => setWriteInOpen(null)}>
                <Box sx={modalBoxStyleNarrower}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Create write-in fencer
                    </Typography>
                    <FormControl style={{ marginTop: 20, width: "100%" }}>
                        <TextField
                            label="First Name"
                            placeholder="First Name"
                            value={writeInFirstName}
                            onChange={e => setWriteInFirstName(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <FormControl style={{ marginTop: 20, width: "100%" }}>
                        <TextField
                            label="Last Name"
                            placeholder="Last Name"
                            value={writeInLastName}
                            onChange={e => setWriteInLastName(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <Button
                        variant="contained"
                        color="success"
                        className={`${!writeInFirstName.trim() || !writeInLastName.trim() ? "disabledButton" : ""}`}
                        disabled={!writeInFirstName.trim() || !writeInLastName.trim()}
                        style={{ marginTop: 15 }}
                        onClick={submitWriteInFencer}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default function DualMeetInfo() {
    const { id } = useParams<{ id: string }>();

    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const [dualMeetInfo, setDualMeetInfo] = useState<IDualMeet | null>(null);
    const [correspondingDualMeetInfo, setCorrespondingDualMeetInfo] = useState<IDualMeet_DB | null>(null);
    const [eventInfo, setEventInfo] = useState<ICollegeEvent | null>(null);
    const [team1Info, setTeam1Info] = useState<IDualMeetTeam | null>(null);
    const [team2Info, setTeam2Info] = useState<IDualMeetTeam | null>(null);
    const [team1Fencers, setTeam1Fencers] = useState<Record<Weapon, Map<IExistingFencer | IWriteInFencer, number>>>({
        Sabre: new Map(),
        Foil: new Map(),
        Epee: new Map()
    });
    const [team2Fencers, setTeam2Fencers] = useState<Record<Weapon, Map<IExistingFencer | IWriteInFencer, number>>>({
        Sabre: new Map(),
        Foil: new Map(),
        Epee: new Map()
    });
    const [boutsRaw, setBoutsRaw] = useState<IDualMeetBout[]>([]);
    const [weapon, setWeapon] = useState<Weapon>("Sabre");
    const [currentBoutID, setCurrentBoutID] = useState<string | null>(null);
    const [currentBoutLog, setCurrentBoutLog] = useState<IBoutEvent[]>([]);
    const [lineupOpen, setLineupOpen] = useState<BoutSide | null>(null);
    const [medicalInfoOpen, setMedicalInfoOpen] = useState(false);
    const [medicalInfoSide, setMedicalInfoSide] = useState<"left" | "right">("left");
    const [scoringLockedOpen, setScoringLockedOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [boutViewerExpanded, setBoutViewerExpanded] = useState(false);

    const [viewingPins, setViewingPins] = useState(false);
    const [showingPINInput, setShowingPINInput] = useState(false);
    const [enteringSignaturePIN, setEnteringSignaturePIN] = useState(false);
    const [enteredPIN, setEnteredPIN] = useState<number | null>(null);
    const [PINErrorMessage, setPINErrorMessage] = useState("");
    const [endingMeet, setEndingMeet] = useState(false);
    const [deletingMeet, setDeletingMeet] = useState(false);
    const [exportingMeet, setExportingMeet] = useState(false);
    const [unlockingMeet, setUnlockingMeet] = useState(false);
    const [repairingScore, setRepairingScore] = useState(false);

    const [exportingCover, setExportingCover] = useState(false);
    const [exportingSabre, setExportingSabre] = useState(false);
    const [exportingFoil, setExportingFoil] = useState(false);
    const [exportingEpee, setExportingEpee] = useState(false);
    const [exportingAll, setExportingAll] = useState(false);

    const [configuringRefs, setConfiguringRefs] = useState(false);
    const [configuringSabre, setConfiguringSabre] = useState(false);
    const [configuringFoil, setConfiguringFoil] = useState(false);
    const [configuringEpee, setConfiguringEpee] = useState(false);
    const [refNameSabre, setRefNameSabre] = useState("");
    const [refNameFoil, setRefNameFoil] = useState("");
    const [refNameEpee, setRefNameEpee] = useState("");

    const [error, setError] = useState("");
    const [exportError, setExportError] = useState("");

    const [signingTab, setSigningTab] = useState(0);
    const [choosingSignature, setChoosingSignature] = useState(false);
    const [signing, setSigning] = useState(false);
    const [signingWeapon, setSigningWeapon] = useState<keyof typeof weaponToSignatureKey>("Sabre");
    const [signature, setSignature] = useState("");
    const existingSignature: string | undefined = dualMeetInfo?.signatures?.[weaponToSignatureKey[signingWeapon]];
    const isTeamSignature = signingWeapon === "Team A" || signingWeapon === "Team B";
    const signatureTitle = `${signingWeapon}${!isTeamSignature ? " Referee" : ""} Signature`;

    const [editingDate, setEditingDate] = useState(false);
    const [newDate, setNewDate] = useState(new Date());

    const storeEnteredPIN = useSelector((s: ReduxState) => s.enteredPIN);
    const storeEnteredPINExpiration = useSelector((s: ReduxState) => s.enteredPINExpiration);

    const currentBoutIDRef = useRef<string | null>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tabsRef = useRef<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bottomRef = useRef<any>();

    const history = useHistory();
    const dispatch = useDispatch();
    const size = useWindowSize();
    const DB = useDatabase();

    const handleDualMeet = async (result: DBResult<IDualMeet>) => {
        if (result.status === "fail") return setError(result.data);
        const { data } = result;
        setDualMeetInfo(data);
        setTeam1Info(data.team1);
        setTeam2Info(data.team2);
        setNewDate(new Date(data.startedAt));
        {
            const leRoster: Record<Weapon, Map<IExistingFencer | IWriteInFencer, number>> = {
                Sabre: new Map(),
                Foil: new Map(),
                Epee: new Map()
            };
            for (const weapon in data.team1.fencers) {
                const weaponRoster = data.team1.fencers[weapon as Weapon];
                for (const fencerData of weaponRoster) {
                    leRoster[weapon].set(fencerData, fencerData.home);
                }
            }
            setTeam1Fencers(leRoster);
        }
        {
            const leRoster: Record<Weapon, Map<IExistingFencer | IWriteInFencer, number>> = {
                Sabre: new Map(),
                Foil: new Map(),
                Epee: new Map()
            };
            for (const weapon in data.team2.fencers) {
                const weaponRoster = data.team2.fencers[weapon as Weapon];
                for (const fencerData of weaponRoster) {
                    leRoster[weapon].set(fencerData, fencerData.home);
                }
            }
            setTeam2Fencers(leRoster);
        }
    };

    useEffect(() => {
        DB.getDualMeet(id, handleDualMeet).then(result => {
            if (result.status === "fail") return setError(result.data);
            const { data } = result;
            Promise.all(
                data.bouts.map((l, idx) =>
                    DB.getBout(l.id, bout => {
                        if (bout.status === "fail") return;
                        setBoutsRaw(boutsRaw => {
                            if (!boutsRaw.length) return boutsRaw;
                            const copy = [...boutsRaw];
                            copy.splice(idx, 1, bout.data);
                            DB.getBoutEventsFromArray(bout.data.log || []).then(d => {
                                if (currentBoutIDRef.current === l.id) {
                                    setCurrentBoutLog(d.filter(isSuccess).map(l => l.data));
                                }
                            });
                            return copy;
                        });
                    })
                )
            ).then(result => setBoutsRaw(result.filter(isSuccess).map(l => l.data)));
        });
        return () => {
            DB.stopListeningDualMeet(id);
            for (const bout of dualMeetInfo?.bouts || []) {
                DB.stopListeningBout(bout.id);
            }
        };
    }, []);

    useEffect(() => {
        setRefNameSabre(dualMeetInfo?.sabreReferee || "");
    }, [dualMeetInfo?.sabreReferee]);

    useEffect(() => {
        setRefNameFoil(dualMeetInfo?.foilReferee || "");
    }, [dualMeetInfo?.foilReferee]);

    useEffect(() => {
        setRefNameEpee(dualMeetInfo?.epeeReferee || "");
    }, [dualMeetInfo?.epeeReferee]);

    const handleSetCorrespondingDualMeetInfo = (result: DBResult<IDualMeet>) => {
        if (isSuccess(result)) {
            setCorrespondingDualMeetInfo(result.data);
        } else {
            setError(result.data);
        }
    };

    useEffect(() => {
        if (!dualMeetInfo?.correspondingMeet) return;
        DB.getDualMeetRaw(dualMeetInfo?.correspondingMeet).then(handleSetCorrespondingDualMeetInfo);
    }, [dualMeetInfo?.correspondingMeet]);

    useEffect(() => {
        if (!dualMeetInfo?.eventID) return;
        DB.getCollegeEvent(dualMeetInfo?.eventID).then(event => (isSuccess(event) ? setEventInfo(event.data) : setError(event.data)));
    }, [dualMeetInfo?.eventID]);

    useEffect(() => {
        currentBoutIDRef.current = currentBoutID;
        const currentBout = boutsRaw.find(l => l.id === currentBoutID);
        if (currentBout) {
            // TODO: what is this bro
            Promise.all((currentBout.log || []).map(DB.getBoutEvent.bind(DB))).then((log: DBResult<IBoutEvent>[]) =>
                setCurrentBoutLog(log.filter(isSuccess).map(l => l.data))
            );
        }
    }, [currentBoutID]);

    const currentBout = boutsRaw.find(l => l.id === currentBoutID);

    const exportMeet = async (weapon: Weapon | "All" | "Cover") => {
        if (!dualMeetInfo) return;

        const setterObj = {
            Sabre: setExportingSabre,
            Foil: setExportingFoil,
            Epee: setExportingEpee,
            Cover: setExportingCover,
            All: setExportingAll
        };

        setterObj[weapon](true);

        const meet = dualMeetInfo;

        let leftType: "Varsity" | "Club" | "JV" = "Varsity";
        let rightType: "Varsity" | "Club" | "JV" = "Varsity";

        const team1Result = await DB.getTeam(meet.team1.id as string);
        const team2Result = await DB.getTeam(meet.team2.id as string);
        if (team1Result.status === "fail") return setExportError(team1Result.data);
        if (team2Result.status === "fail") return setExportError(team2Result.data);
        const [team1, team2] = [team1Result.data, team2Result.data];
        const org1Result = await DB.getOrganizationFromTeam(team1.id);
        const org2Result = await DB.getOrganizationFromTeam(team2.id);
        if (org1Result.status === "fail") return setExportError(org1Result.data);
        if (org2Result.status === "fail") return setExportError(org2Result.data);
        const [org1, org2] = [org1Result.data, org2Result.data];

        if (team1?.region === "Club") leftType = "Club";
        if (team2?.region === "Club") rightType = "Club";
        if (team1?.id && (org1?.boysJVTeam === team1.id || org1?.girlsJVTeam === team1.id)) {
            leftType = "JV";
        }
        if (team2?.id && (org2?.boysJVTeam === team2.id || org2?.girlsJVTeam === team2.id)) {
            rightType = "JV";
        }

        const womens = meet.team1.name.includes("Women") || meet.team2.name.includes("Women");

        const pdf = await exportCollegeDualMeet(
            null,
            dualMeetInfo,
            weapon === "All" || weapon === "Cover" ? bouts : bouts[weapon],
            womens ? "girls" : "boys",
            weapon,
            leftType,
            rightType,
            eventInfo?.hostName || team1?.name
        );
        const pdfArray = await pdf.save();

        const blob = new Blob([pdfArray], { type: "application/pdf" });
        const link = window.URL.createObjectURL(blob);
        window.open(link);

        setterObj[weapon](false);
    };

    const setMeetPublished = () => {
        if (!dualMeetInfo?.published) {
            DB.publishDualMeet(id);
        }
    };

    const bouts = (() => {
        const res: Record<Weapon, IDualMeetBout[]> = {
            Sabre: [],
            Foil: [],
            Epee: []
        };

        for (const bout of boutsRaw) {
            res[bout.weapon].push(bout);
        }

        return res;
    })();

    const [score1, score2] = meetScoreFromBouts(boutsRaw);

    const timeouts1 = Object.values(bouts)
        .flat()
        .reduce((acc, cur) => acc + Number(cur?.fencer1?.timeout), 0);
    const timeouts2 = Object.values(bouts)
        .flat()
        .reduce((acc, cur) => acc + Number(cur?.fencer2?.timeout), 0);

    const squadScores1 = (() => {
        const scores = {
            Sabre: 0,
            Foil: 0,
            Epee: 0
        };
        for (const weapon in bouts) {
            for (const bout of bouts[weapon]) {
                if (boutWinner(bout) === BoutSide.Fencer1) {
                    scores[weapon]++;
                }
            }
        }
        return scores;
    })();

    const squadScores2 = (() => {
        const scores = {
            Sabre: 0,
            Foil: 0,
            Epee: 0
        };
        for (const weapon in bouts) {
            for (const bout of bouts[weapon]) {
                if (boutWinner(bout) === BoutSide.Fencer2) {
                    scores[weapon]++;
                }
            }
        }
        return scores;
    })();

    const individualStats = getIndividualStatsForBouts(bouts[weapon]);

    const liveBouts = boutsRaw.filter(l => l.startedAt && !l.endedAt);

    const administratorOfTeam: "left" | "right" | null = (() => {
        if (!userInfo) return null;

        if (team1Info?.administrators?.includes(userInfo.id) || team1Info?.managers?.includes(userInfo.id)) {
            return "left";
        } else if (team2Info?.administrators?.includes(userInfo.id) || team2Info?.managers?.includes(userInfo.id)) {
            return "right";
        }

        {
            const team1IDs = Object.values(team1Fencers).flatMap(l => [...l.keys()]);
            if (userInfo.linkedFencerIds.some(l => team1IDs.some(j => "id" in j && j.id === l))) return "left";
        }

        {
            const team2IDs = Object.values(team2Fencers).flatMap(l => [...l.keys()]);
            if (userInfo.linkedFencerIds.some(l => team2IDs.some(j => "id" in j && j.id === l))) return "right";
        }

        return null;
    })();

    if (error === ERROR_DUAL_MEET_DOES_NOT_EXIST) {
        return <ErrorPage code={404} message="The requested dual meet could not be found." />;
    } else if (error) {
        return <ErrorPage message={error} />;
    }

    const onLiveNowBoutClick = (l: IDualMeetBout) => {
        setWeapon(l.weapon);
        setCurrentBoutID(l.id);
        tabsRef?.current?.switchTab(2);
        bottomRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const setClipboardPIN = (type: "team" | "event") => {
        if (type === "team") {
            if (!administratorOfTeam) return;

            const PIN = administratorOfTeam === "left" ? dualMeetInfo?.pin1 : dualMeetInfo?.pin2;

            if (!PIN) return;

            navigator.clipboard.writeText(PIN.toString());
        } else if (type === "event") {
            if (!eventInfo) return;

            const PIN = eventInfo.refereePin;

            if (!PIN) return;

            navigator.clipboard.writeText(PIN.toString());
        }
    };

    const tryShowingPINInput = (signature = false) => {
        const success = () => {
            if (signature) {
                setSigning(true);
            } else {
                let url = `/dualMeetScorer/${id}`;
                if (currentBoutID) {
                    url += `?bout=${currentBoutID}`;
                }
                history.push(url);
            }
        };

        const showPin = () => {
            if (signature) {
                setEnteringSignaturePIN(true);
            }
            setShowingPINInput(true);
        };

        if ((userInfo?.flags || 0) & UserFlag.MeetManager) return success();
        if (dualMeetInfo?.endedAt && !dualMeetUnlocked(dualMeetInfo)) return setScoringLockedOpen(true);

        if (administratorOfTeam !== null) {
            success();
        } else if ((storeEnteredPINExpiration || -Infinity) < Date.now()) {
            dispatch({ type: "resetEnteredPINV2" });
            showPin();
        } else if (
            storeEnteredPIN === dualMeetInfo?.pin1 ||
            storeEnteredPIN === dualMeetInfo?.pin2 ||
            storeEnteredPIN === eventInfo?.refereePin
        ) {
            success();
        } else {
            showPin();
        }
    };

    const tryPIN = () => {
        if (!enteredPIN) {
            return setPINErrorMessage("You must enter a PIN.");
        }

        if (enteredPIN.toString().length !== 5) {
            return setPINErrorMessage("The PIN must be 5 digits long.");
        }

        if (enteredPIN !== dualMeetInfo?.pin1 && enteredPIN !== dualMeetInfo?.pin2 && enteredPIN !== eventInfo?.refereePin) {
            return setPINErrorMessage("The PIN you entered is incorrect.");
        }

        dispatch({ type: "setEnteredPINV2", payload: enteredPIN });
        if (enteringSignaturePIN) {
            setShowingPINInput(false);
            setSigning(true);
        } else {
            let url = `/dualMeetScorer/${id}`;
            if (currentBoutID) {
                url += `?bout=${currentBoutID}`;
            }
            history.push(url);
        }
    };

    const endMeet = (forfeit: "left" | "right" | "force") => {
        if (forfeit === "force" && ((administratorOfTeam && score1 + score2 === 27) || (userInfo?.flags || 0) & UserFlag.MeetManager)) {
            DB.endDualMeet(id, forfeit);
        } else if (administratorOfTeam) {
            DB.endDualMeet(id, forfeit);
        }
    };

    const deleteMeet = async () => {
        const redirectURL = dualMeetInfo?.eventID ? `/eventInfo/${dualMeetInfo?.eventID}` : `/events`;
        setDualMeetInfo(null);
        setTimeout(async () => {
            await DB.deleteDualMeet(id);
            history.push(redirectURL);
        }, 1000);
    };

    const unlockMeet = async () => {
        await DB.unlockDualMeet(id);
        setUnlockingMeet(false);
    };

    const repairScore = async () => {
        await DB.repairMeetScore(id);
        setRepairingScore(false);
    };

    const submitNewDate = async () => {
        if (!id || !newDate) return;
        await DB.setDualMeetDate(id, newDate);
        setEditingDate(false);
    };

    const saveReferee = async (weapon: Weapon) => {
        const relevantName = weapon === "Sabre" ? refNameSabre : weapon === "Foil" ? refNameFoil : refNameEpee;

        await DB.setMeetReferee(id, { [weapon]: relevantName || null });

        if (weapon === "Sabre") {
            setConfiguringSabre(false);
        }
        if (weapon === "Foil") {
            setConfiguringFoil(false);
        }
        if (weapon === "Epee") {
            setConfiguringEpee(false);
        }
    };

    const startSigning = (weapon: keyof typeof weaponToSignatureKey) => {
        const signatureInDb = dualMeetInfo?.signatures?.[weaponToSignatureKey[weapon]];
        if (signatureInDb) return;
        setSigningWeapon(weapon);
        tryShowingPINInput(true);
    };

    const sign = async () => {
        if (!dualMeetInfo) return;
        await DB.signMeet(dualMeetInfo.id, weaponToSignatureKey[signingWeapon] as DualMeetSignatureKey, signature);
        setSigning(false);
        setSignature("");
    };

    const leftFencer = currentBout?.switchedSides ? currentBout?.fencer2 : currentBout?.fencer1;
    const rightFencer = currentBout?.switchedSides ? currentBout?.fencer1 : currentBout?.fencer2;

    const TimeoutsComponent = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%"
                }}
            >
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            padding: "8px 10px",
                            width: "calc(50% - 1px)",
                            borderRight: "1px solid #999"
                        }}
                    >
                        <Typography sx={{ fontSize: 18 }} variant="subtitle1" color="text.primary">
                            Overall Score:{" "}
                            <span className="avoidWrap">
                                {score1} - {score2}
                            </span>
                        </Typography>
                    </div>
                    <div style={{ padding: "8px 10px", width: "50%" }}>
                        <Typography sx={{ fontSize: 18 }} variant="subtitle1" color="text.primary">
                            Total Timeouts:{" "}
                            <span className="avoidWrap">
                                {timeouts1} - {timeouts2}
                            </span>
                        </Typography>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            padding: "8px 10px",
                            width: "calc(50% - 1px)",
                            borderRight: "1px solid #999"
                        }}
                    >
                        <Typography
                            sx={{ fontSize: 18, cursor: "pointer" }}
                            onClick={() => setWeapon("Sabre")}
                            variant="subtitle1"
                            color="text.primary"
                        >
                            Sabre Score:{" "}
                            <span className="avoidWrap">
                                {squadScores1.Sabre} - {squadScores2.Sabre}
                            </span>
                        </Typography>
                    </div>
                    <div style={{ padding: "8px 10px", width: "50%" }}>
                        <Typography
                            sx={{ fontSize: 18, cursor: "pointer" }}
                            onClick={() => setWeapon("Sabre")}
                            variant="subtitle1"
                            color="text.primary"
                        >
                            Sabre Timeouts:{" "}
                            <span className="avoidWrap">
                                {bouts.Sabre.reduce((acc, cur) => acc + +cur.fencer1.timeout, 0)} -{" "}
                                {bouts.Sabre.reduce((acc, cur) => acc + +cur.fencer2.timeout, 0)}
                            </span>
                        </Typography>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            padding: "8px 10px",
                            width: "calc(50% - 1px)",
                            borderRight: "1px solid #999"
                        }}
                    >
                        <Typography
                            sx={{ fontSize: 18, cursor: "pointer" }}
                            onClick={() => setWeapon("Foil")}
                            variant="subtitle1"
                            color="text.primary"
                        >
                            Foil Score:{" "}
                            <span className="avoidWrap">
                                {squadScores1.Foil} - {squadScores2.Foil}
                            </span>
                        </Typography>
                    </div>
                    <div style={{ padding: "8px 10px", width: "50%" }}>
                        <Typography
                            sx={{ fontSize: 18, cursor: "pointer" }}
                            onClick={() => setWeapon("Foil")}
                            variant="subtitle1"
                            color="text.primary"
                        >
                            Foil Timeouts:{" "}
                            <span className="avoidWrap">
                                {bouts.Foil.reduce((acc, cur) => acc + +cur.fencer1.timeout, 0)} -{" "}
                                {bouts.Foil.reduce((acc, cur) => acc + +cur.fencer2.timeout, 0)}
                            </span>
                        </Typography>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            padding: "8px 10px",
                            width: "calc(50% - 1px)",
                            borderRight: "1px solid #999"
                        }}
                    >
                        <Typography
                            sx={{ fontSize: 18, cursor: "pointer" }}
                            onClick={() => setWeapon("Epee")}
                            variant="subtitle1"
                            color="text.primary"
                        >
                            Epee Score:{" "}
                            <span className="avoidWrap">
                                {squadScores1.Epee} - {squadScores2.Epee}
                            </span>
                        </Typography>
                    </div>
                    <div style={{ padding: "8px 10px", width: "50%" }}>
                        <Typography
                            sx={{ fontSize: 18, cursor: "pointer" }}
                            onClick={() => setWeapon("Epee")}
                            variant="subtitle1"
                            color="text.primary"
                        >
                            Epee Timeouts:{" "}
                            <span className="avoidWrap">
                                {bouts.Epee.reduce((acc, cur) => acc + +cur.fencer1.timeout, 0)} -{" "}
                                {bouts.Epee.reduce((acc, cur) => acc + +cur.fencer2.timeout, 0)}
                            </span>
                        </Typography>
                    </div>
                </div>
                <div style={{ flexGrow: 1 }}>
                    <div
                        style={{
                            height: "100%",
                            width: "calc(50% - 1px)",
                            borderRight: "1px solid #999"
                        }}
                    ></div>
                    <div style={{ height: "100%", width: "50%" }}></div>
                </div>
            </div>
        );
    };

    const WeaponDropdown = () => {
        return (
            <StyledDropdown displayText={weapon} enableElevation={true}>
                <MenuItem
                    key="Sabre"
                    onClick={() => {
                        setWeapon("Sabre");
                        setCurrentBoutID(null);
                    }}
                >
                    Sabre
                </MenuItem>
                <MenuItem
                    key="Foil"
                    onClick={() => {
                        setWeapon("Foil");
                        setCurrentBoutID(null);
                    }}
                >
                    Foil
                </MenuItem>
                <MenuItem
                    key="Epee"
                    onClick={() => {
                        setWeapon("Epee");
                        setCurrentBoutID(null);
                    }}
                >
                    Epee
                </MenuItem>
            </StyledDropdown>
        );
    };

    const bgOptions = {
        Sabre: "rgba(30, 89, 144, 0.5)",
        Foil: "rgba(190, 150, 27, 0.5)",
        Epee: "rgba(186, 186, 186, 0.5)"
    };
    const bgFromWeapon = bgOptions[weapon];

    const runningScores: ([number, number] | null)[] = [];
    for (const bout of bouts[weapon]) {
        const winner = boutWinner(bout);

        const lastRunningScore = runningScores.filter(Boolean)[runningScores.filter(Boolean).length - 1] || [0, 0];
        if (winner === BoutSide.Fencer1) {
            runningScores.push([lastRunningScore[0] + 1, lastRunningScore[1]]);
        } else if (winner === BoutSide.Fencer2) {
            runningScores.push([lastRunningScore[0], lastRunningScore[1] + 1]);
        } else {
            runningScores.push(null);
        }
    }

    const name1 = dualMeetInfo?.name?.split(" vs. ")?.[0];
    const name2 = dualMeetInfo?.name?.split(" vs. ")?.[1];

    const meetManager = Boolean((userInfo?.flags || 0) & UserFlag.MeetManager);
    const canManageMeet = meetManager || administratorOfTeam !== null;
    const canDeleteMeet = meetManager || (userInfo?.id ? dualMeetInfo?.createdBy === userInfo.id : false);

    const openLineup = lineupOpen === BoutSide.Fencer1 ? dualMeetInfo?.team1Lineup : dualMeetInfo?.team2Lineup;

    const canEditLineup1 = Boolean(dualMeetInfo?.team1Lineup?.Sabre && dualMeetInfo?.team1Lineup?.Foil && dualMeetInfo?.team1Lineup?.Epee);
    const canEditLineup2 = Boolean(dualMeetInfo?.team2Lineup?.Sabre && dualMeetInfo?.team2Lineup?.Foil && dualMeetInfo?.team2Lineup?.Epee);

    const medicalInfoFencer = medicalInfoSide === "left" ? leftFencer : rightFencer;

    return (
        <TbTPage className="dualMeetInfoPage">
            {dualMeetInfo ? (
                <>
                    <DualMeetSummary
                        info={dualMeetInfo}
                        eventInfo={eventInfo}
                        score1={score1}
                        score2={score2}
                        canManageMeet={canManageMeet}
                        canDeleteMeet={canDeleteMeet}
                        correspondingMeet={correspondingDualMeetInfo}
                        editDate={() => setEditingDate(true)}
                        publish={() => history.push(`/eventInfo/${dualMeetInfo.eventID}`)}
                        chooseSignature={() => setChoosingSignature(true)}
                        exportMeet={() => setExportingMeet(true)}
                        configureRefs={() => setConfiguringRefs(true)}
                        viewPINs={() => setViewingPins(true)}
                        endMeet={() => setEndingMeet(true)}
                        deleteMeet={() => setDeletingMeet(true)}
                        unlockMeet={() => setUnlockingMeet(true)}
                        repairScore={() => setRepairingScore(true)}
                    />
                    <div className="liveNowBoutsList">
                        {liveBouts.map(l => (
                            <LiveNowBout key={`liveNowBout${l.id}`} data={l} onClick={() => onLiveNowBoutClick(l)} />
                        ))}
                    </div>
                    {size.width > 1000 ? (
                        <>
                            <Stack
                                direction="row"
                                sx={{
                                    maxWidth: 1000,
                                    width: "90vw",
                                    margin: "auto"
                                }}
                                spacing={4}
                            >
                                <Card
                                    sx={{
                                        maxWidth: 500,
                                        width: "90vw",
                                        margin: "auto",
                                        height: 290,
                                        borderRadius: 3
                                    }}
                                >
                                    <CardHeader
                                        title="Dual Meet Summary"
                                        sx={{
                                            backgroundColor: theme =>
                                                theme.palette.mode === "light" ? theme.palette.primary.light : theme.palette.secondary.dark,
                                            fontWeight: "bold !important",
                                            height: "60px"
                                        }}
                                    />
                                    <CardContent
                                        sx={{
                                            padding: "0 !important",
                                            height: "230px"
                                        }}
                                    >
                                        <TimeoutsComponent />
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        maxWidth: 500,
                                        width: "90vw",
                                        margin: "auto",
                                        height: 290,
                                        overflow: "auto",
                                        borderRadius: 3
                                    }}
                                >
                                    <CardHeader
                                        title="Individual Stats"
                                        sx={{
                                            backgroundColor: theme =>
                                                theme.palette.mode === "light" ? theme.palette.primary.light : theme.palette.secondary.dark,
                                            fontWeight: "bold !important",
                                            height: "60px",
                                            position: "relative"
                                        }}
                                        action={
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    left: 12,
                                                    top: 11,
                                                    display: "flex"
                                                }}
                                            >
                                                <WeaponDropdown />
                                            </div>
                                        }
                                    />
                                    <CardContent
                                        sx={{
                                            padding: "0 !important",
                                            height: "auto"
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                color: "black",
                                                fontSize: 18
                                            }}
                                        >
                                            <div
                                                style={{
                                                    minHeight: 230,
                                                    width: 299,
                                                    paddingBottom: 10,
                                                    borderRight: "1px solid #999",
                                                    maxWidth: "50%"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        padding: "10px 0",
                                                        borderBottom: "1px solid #999"
                                                    }}
                                                >
                                                    {team1Info?.abbreviation || "Unknown team"}
                                                </div>
                                                {Object.values(individualStats.team1).map((l, idx) => (
                                                    <div
                                                        key={`individualStatsTeam1${l.name}${idx}`}
                                                        style={{
                                                            margin: "10px 10px 10px 20px",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                textAlign: "left",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                maxWidth: "calc(100% - 50px)"
                                                            }}
                                                        >
                                                            {l.name}
                                                        </span>
                                                        <span
                                                            style={{
                                                                width: "50px",
                                                                marginLeft: "auto"
                                                            }}
                                                        >
                                                            {" "}
                                                            {l.score[0]}-{l.score[1]}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                            <div
                                                style={{
                                                    minHeight: 230,
                                                    width: 300,
                                                    paddingBottom: 10,
                                                    maxWidth: "50%"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        padding: "10px 0",
                                                        borderBottom: "1px solid #999"
                                                    }}
                                                >
                                                    {team2Info?.abbreviation || "Unknown team"}
                                                </div>
                                                {Object.values(individualStats.team2).map((l, idx) => (
                                                    <div
                                                        key={`individualStatsTeam2${l.name}${idx}`}
                                                        style={{
                                                            margin: "10px 10px 10px 20px",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                textAlign: "left",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                maxWidth: "calc(100% - 50px)"
                                                            }}
                                                        >
                                                            {l.name}
                                                        </span>
                                                        <span
                                                            style={{
                                                                width: "50px",
                                                                marginLeft: "auto"
                                                            }}
                                                        >
                                                            {" "}
                                                            {l.score[0]}-{l.score[1]}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Stack>
                            <Card
                                sx={{
                                    maxWidth: 1000,
                                    width: "90vw",
                                    margin: "20px auto 0 auto"
                                }}
                                ref={bottomRef}
                            >
                                <CardHeader
                                    title="Bouts Viewer"
                                    sx={{
                                        backgroundColor: theme =>
                                            theme.palette.mode === "light" ? theme.palette.primary.light : theme.palette.secondary.dark,
                                        fontWeight: "bold !important",
                                        height: "60px",
                                        position: "relative"
                                    }}
                                    action={
                                        <div
                                            style={{
                                                display: "flex",
                                                position: "absolute",
                                                left: 12,
                                                top: 11,
                                                width: "100%",
                                                paddingRight: 12
                                            }}
                                        >
                                            {(userInfo?.flags || 0) & UserFlag.MeetManager && currentBout ? (
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    href={`/dualMeetScorer/${id}?bout=${currentBout.id}`}
                                                    className="dualMeetInfoEditBoutButton"
                                                    style={{
                                                        marginRight: 12
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            ) : (
                                                <Button
                                                    onClick={() => tryShowingPINInput()}
                                                    color="primary"
                                                    variant="contained"
                                                    style={{
                                                        marginRight: 12
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                            <div
                                                style={{
                                                    marginRight: "10px"
                                                }}
                                            >
                                                <WeaponDropdown />
                                            </div>
                                            {(meetManager || administratorOfTeam === "left") && !canEditLineup1 && (
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => setLineupOpen(BoutSide.Fencer1)}
                                                    sx={{
                                                        marginRight: "auto"
                                                    }}
                                                >
                                                    Edit lineup {meetManager ? "(3, 1, 2)" : ""}
                                                </Button>
                                            )}
                                            {(meetManager || administratorOfTeam === "right") && !canEditLineup2 && (
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => setLineupOpen(BoutSide.Fencer2)}
                                                    sx={{
                                                        marginRight: "10px"
                                                    }}
                                                >
                                                    Edit lineup {meetManager ? "(6, 5, 4)" : ""}
                                                </Button>
                                            )}
                                            <Button
                                                onClick={() => setSettingsOpen(true)}
                                                color="primary"
                                                variant="contained"
                                                sx={{ marginRight: "10px" }}
                                            >
                                                Settings
                                            </Button>
                                        </div>
                                    }
                                />
                                <CardContent sx={{ padding: "0 !important" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            backgroundColor: "black"
                                        }}
                                    >
                                        <Box>
                                            <Box className="boutViewerList" sx={{ backgroundColor: bgFromWeapon, maxHeight: boutViewerExpanded ? 4000 : 290 }}>
                                                <BoutsList
                                                    bouts={bouts}
                                                    boutsRaw={boutsRaw}
                                                    runningScores={runningScores}
                                                    setCurrentBoutID={setCurrentBoutID}
                                                    weapon={weapon}
                                                    tabsRef={tabsRef}
                                                />
                                            </Box>
                                            <Box onClick={() => setBoutViewerExpanded(u => !u)} className="boutViewerToggleButton">
                                                {boutViewerExpanded ? "Collapse" : "Expand"}
                                            </Box>
                                        </Box>
                                        <div
                                            style={{
                                                backgroundColor: "rgba(0, 0, 0, 0.95)",
                                                textAlign: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                color: "white",
                                                flexGrow: "1",
                                                fontSize: 18,
                                                maxHeight: 325
                                            }}
                                        >
                                            {currentBout && leftFencer && rightFencer ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        height: "100%"
                                                    }}
                                                    key={`currentBout${currentBout.order}`}
                                                >
                                                    <BoutFencerSection
                                                        bout={currentBout}
                                                        fencer={leftFencer}
                                                        mobile={false}
                                                        side="left"
                                                        colorBasedOnWinner={LocalSettings.getColorByWinner()}
                                                        setMedicalInfoOpen={setMedicalInfoOpen}
                                                        setMedicalInfoSide={setMedicalInfoSide}
                                                    />
                                                    <BoutLog log={currentBoutLog} mobile={false} />
                                                    <BoutFencerSection
                                                        bout={currentBout}
                                                        fencer={rightFencer}
                                                        mobile={false}
                                                        side="right"
                                                        colorBasedOnWinner={LocalSettings.getColorByWinner()}
                                                        setMedicalInfoOpen={setMedicalInfoOpen}
                                                        setMedicalInfoSide={setMedicalInfoSide}
                                                    />
                                                </div>
                                            ) : (
                                                <p>You have not selected any bouts!</p>
                                            )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <div style={{ height: "20px" }}></div>
                        </>
                    ) : (
                        <div ref={bottomRef}>
                            <TbTTabView ref={tabsRef}>
                                <TbTTab title="Summary">
                                    <Card
                                        sx={{
                                            maxWidth: 500,
                                            width: "90vw",
                                            margin: "auto",
                                            borderRadius: 3
                                        }}
                                    >
                                        <CardHeader
                                            title="Dual Meet Summary"
                                            sx={{
                                                backgroundColor: theme =>
                                                    theme.palette.mode === "light"
                                                        ? theme.palette.primary.light
                                                        : theme.palette.secondary.dark,
                                                fontWeight: "bold !important"
                                            }}
                                        />
                                        <CardContent sx={{ padding: "0 !important" }}>
                                            <TimeoutsComponent />
                                        </CardContent>
                                    </Card>
                                </TbTTab>
                                <TbTTab title="Individual Stats">
                                    <Card
                                        sx={{
                                            maxWidth: 500,
                                            width: "90vw",
                                            margin: "auto",
                                            maxHeight: 300,
                                            overflow: "auto",
                                            borderRadius: 3
                                        }}
                                    >
                                        <CardHeader
                                            title="Individual Stats"
                                            sx={{
                                                backgroundColor: theme =>
                                                    theme.palette.mode === "light"
                                                        ? theme.palette.primary.light
                                                        : theme.palette.secondary.dark,
                                                fontWeight: "bold !important",
                                                position: "relative",
                                                paddingBottom: 6.5,
                                                paddingTop: 1
                                            }}
                                            action={
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        left: "50%",
                                                        transform: "translateX(-50%)",
                                                        top: 45,
                                                        display: "flex"
                                                    }}
                                                >
                                                    <WeaponDropdown />
                                                </div>
                                            }
                                        />
                                        <CardContent
                                            sx={{
                                                padding: "0 !important",
                                                height: "auto"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    color: "black",
                                                    fontSize: 18
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        minHeight: 230,
                                                        width: 299,
                                                        paddingBottom: 10,
                                                        borderRight: "1px solid #999",
                                                        maxWidth: "50%"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            padding: "10px 0",
                                                            borderBottom: "1px solid #999"
                                                        }}
                                                    >
                                                        {team1Info?.abbreviation || "Unknown team"}
                                                    </div>
                                                    {Object.values(individualStats.team1).map((l, idx) => (
                                                        <p
                                                            key={`individualStatsTeam1${l.name}${idx}`}
                                                            style={{
                                                                margin: 10
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    maxWidth: "calc(100% - 5ch)",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    display: "inline-block",
                                                                    verticalAlign: "bottom"
                                                                }}
                                                            >
                                                                {l.name}
                                                            </span>
                                                            : {l.score[0]}-{l.score[1]}
                                                        </p>
                                                    ))}
                                                </div>
                                                <div
                                                    style={{
                                                        minHeight: 230,
                                                        width: 300,
                                                        paddingBottom: 10,
                                                        maxWidth: "50%"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            padding: "10px 0",
                                                            borderBottom: "1px solid #999"
                                                        }}
                                                    >
                                                        {team2Info?.abbreviation || "Unknown team"}
                                                    </div>
                                                    {Object.values(individualStats.team2).map((l, idx) => (
                                                        <p
                                                            key={`individualStatsTeam2${l.name}${idx}`}
                                                            style={{
                                                                margin: 10
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    maxWidth: "calc(100% - 5ch)",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    display: "inline-block",
                                                                    verticalAlign: "bottom"
                                                                }}
                                                            >
                                                                {l.name}
                                                            </span>
                                                            : {l.score[0]}-{l.score[1]}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </TbTTab>
                                <TbTTab title="Selected Bout">
                                    <Card
                                        sx={{
                                            maxWidth: 500,
                                            width: "90vw",
                                            margin: "auto",
                                            maxHeight: currentBout ? 515 : 315,
                                            overflow: "auto",
                                            borderRadius: 3
                                        }}
                                    >
                                        <CardHeader
                                            title="Selected Bout"
                                            action={
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        left: 12,
                                                        top: 15
                                                    }}
                                                >
                                                    {(userInfo?.flags || 0) & UserFlag.MeetManager && currentBout ? (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            href={`/dualMeetScorer/${id}?bout=${currentBout.id}`}
                                                            className="dualMeetInfoEditBoutButton"
                                                        >
                                                            Edit
                                                        </Button>
                                                    ) : (
                                                        <Button onClick={() => tryShowingPINInput()} color="primary" variant="contained">
                                                            Edit
                                                        </Button>
                                                    )}
                                                </div>
                                            }
                                            sx={{
                                                position: "relative",
                                                backgroundColor: theme =>
                                                    theme.palette.mode === "light"
                                                        ? theme.palette.primary.light
                                                        : theme.palette.secondary.dark,
                                                fontWeight: "bold !important"
                                            }}
                                        />
                                        <CardContent sx={{ padding: "0 !important" }}>
                                            <div
                                                style={{
                                                    margin: "auto",
                                                    flexShrink: 0
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: "rgba(0, 0, 0, 0.95)",
                                                        textAlign: "center",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        color: "white",
                                                        height: (currentBout ? 500 : 300) - 52,
                                                        fontSize: 18
                                                    }}
                                                >
                                                    {currentBout && leftFencer && rightFencer ? (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                height: 248
                                                            }}
                                                            key={`currentBout${currentBout.order}`}
                                                        >
                                                            <BoutFencerSection
                                                                bout={currentBout}
                                                                fencer={leftFencer}
                                                                mobile={true}
                                                                side="left"
                                                                colorBasedOnWinner={LocalSettings.getColorByWinner()}
                                                                setMedicalInfoOpen={setMedicalInfoOpen}
                                                                setMedicalInfoSide={setMedicalInfoSide}
                                                            />
                                                            <BoutFencerSection
                                                                bout={currentBout}
                                                                fencer={rightFencer}
                                                                mobile={true}
                                                                side="right"
                                                                colorBasedOnWinner={LocalSettings.getColorByWinner()}
                                                                setMedicalInfoOpen={setMedicalInfoOpen}
                                                                setMedicalInfoSide={setMedicalInfoSide}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <p>You have not selected any bouts!</p>
                                                    )}
                                                    {currentBout ? <BoutLog log={currentBoutLog} mobile={true} /> : null}
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </TbTTab>
                                <TbTTab title="All Bouts">
                                    <Card
                                        sx={{
                                            maxWidth: 500,
                                            width: "90vw",
                                            margin: "auto",
                                            maxHeight: 375,
                                            overflow: "auto",
                                            borderRadius: 3
                                        }}
                                    >
                                        <CardHeader
                                            title="All Bouts"
                                            sx={{
                                                backgroundColor: theme =>
                                                    theme.palette.mode === "light"
                                                        ? theme.palette.primary.light
                                                        : theme.palette.secondary.dark,
                                                fontWeight: "bold !important",
                                                position: "relative"
                                            }}
                                            action={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        position: "absolute",
                                                        left: 8,
                                                        top: 14
                                                    }}
                                                >
                                                    <WeaponDropdown />
                                                </div>
                                            }
                                        />
                                        <CardContent sx={{ padding: "0 !important" }}>
                                            <div
                                                style={{
                                                    margin: "auto",
                                                    flexShrink: 0,
                                                    backgroundColor: "black"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: bgFromWeapon,
                                                        textAlign: "center",
                                                        minHeight: 325
                                                    }}
                                                >
                                                    <BoutsList
                                                        bouts={bouts}
                                                        boutsRaw={boutsRaw}
                                                        runningScores={runningScores}
                                                        weapon={weapon}
                                                        setCurrentBoutID={setCurrentBoutID}
                                                        tabsRef={tabsRef}
                                                    />
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </TbTTab>
                            </TbTTabView>
                        </div>
                    )}
                </>
            ) : (
                <CommonLoading />
            )}
            <Dialog open={viewingPins} onClose={() => setViewingPins(false)}>
                <DialogTitle>Scoring PIN</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ margin: "10px 0", fontWeight: 400 }}>
                        This PIN allows your team members, coaches, or referees to help score this dual meet.{" "}
                        <strong>Do not give this PIN to anyone whom you do not want editing the meet score!</strong>
                    </DialogContentText>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <DialogContentText style={{ margin: 0, fontWeight: 400, color: "black" }}>
                            Scoring PIN: <strong>{administratorOfTeam === "left" ? dualMeetInfo?.pin1 : dualMeetInfo?.pin2}</strong>
                        </DialogContentText>
                        <IconButton
                            onClick={() => {
                                setClipboardPIN("event");
                                setViewingPins(false);
                            }}
                        >
                            <Icon path={mdiContentCopy} size={1} horizontal vertical rotate={180} />
                        </IconButton>
                    </div>
                    {eventInfo && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "10px"
                            }}
                        >
                            <DialogContentText style={{ margin: 0, fontWeight: 400, color: "black" }}>
                                Event referee PIN: <strong>{eventInfo.refereePin}</strong>
                            </DialogContentText>
                            <IconButton
                                onClick={() => {
                                    setClipboardPIN("event");
                                    setViewingPins(false);
                                }}
                            >
                                <Icon path={mdiContentCopy} size={1} horizontal vertical rotate={180} />
                            </IconButton>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setViewingPins(false);
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deletingMeet} onClose={() => setDeletingMeet(false)}>
                <DialogTitle>Delete meet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this meet? <strong>This cannot be undone!</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
                    <Button
                        sx={{ marginRight: "10px" }}
                        variant="text"
                        color="error"
                        onClick={() => {
                            setDeletingMeet(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            deleteMeet();
                            setDeletingMeet(false);
                        }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={endingMeet} onClose={() => setEndingMeet(false)}>
                <DialogTitle>End meet</DialogTitle>
                <DialogContent>
                    {score1 + score2 === 27 ? (
                        <>
                            <DialogContentText>Do you want to end this dual meet?</DialogContentText>
                        </>
                    ) : (
                        <>
                            <DialogContentText>
                                Do you want to forfeit this dual meet? <strong>This will forfeit all of your unfinished bouts!</strong>
                            </DialogContentText>
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
                    {(userInfo?.flags || 0) & UserFlag.MeetManager || score1 + score2 === 27 ? (
                        <>
                            <Button
                                sx={{ marginRight: "10px" }}
                                variant="contained"
                                color="success"
                                onClick={() => {
                                    endMeet("force");
                                    setEndingMeet(false);
                                }}
                            >
                                End meet
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                sx={{ marginRight: "10px" }}
                                variant="contained"
                                color="success"
                                onClick={() => {
                                    endMeet(administratorOfTeam!);
                                    setEndingMeet(false);
                                }}
                            >
                                Forfeit meet
                            </Button>
                        </>
                    )}
                    {(userInfo?.flags || 0) & UserFlag.MeetManager ? (
                        <>
                            <Button
                                variant="contained"
                                color="success"
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                    endMeet("left");
                                    setEndingMeet(false);
                                }}
                            >
                                Forfeit left
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                    endMeet("right");
                                    setEndingMeet(false);
                                }}
                            >
                                Forfeit right
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                            setEndingMeet(false);
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal open={showingPINInput} onClose={() => setShowingPINInput(false)}>
                <Box sx={modalBoxStyleNarrower}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        {enteringSignaturePIN ? "Enter PIN to sign this meet" : "Enter team PIN to score this meet"}
                    </Typography>
                    <TextField
                        placeholder="Enter PIN"
                        label="Enter PIN"
                        style={{ margin: "10px 0", width: 150 }}
                        type="text"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        onInput={e => setEnteredPIN(Number((e.target as HTMLTextAreaElement).value || "0"))}
                    />
                    <br />
                    <Button variant="contained" color="success" onClick={tryPIN}>
                        Confirm
                    </Button>
                    {PINErrorMessage ? (
                        <Alert sx={{ marginTop: "10px" }} variant="filled" severity="error" onClose={() => setPINErrorMessage("")}>
                            {PINErrorMessage}
                        </Alert>
                    ) : null}
                </Box>
            </Modal>
            <Dialog open={exportingMeet} onClose={() => setExportingMeet(false)}>
                <DialogTitle>Export Meet to PDF</DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    <List>
                        <ListItemButton disabled={exportingCover} onClick={() => exportMeet("Cover")}>
                            <ListItemText primary="Cover sheet" secondary={exportingCover ? "Exporting..." : null} />
                        </ListItemButton>
                        <ListItemButton disabled={exportingSabre} onClick={() => exportMeet("Sabre")}>
                            <ListItemText primary="Sabre" secondary={exportingSabre ? "Exporting..." : null} />
                        </ListItemButton>
                        <ListItemButton disabled={exportingFoil} onClick={() => exportMeet("Foil")}>
                            <ListItemText primary="Foil" secondary={exportingFoil ? "Exporting..." : null} />
                        </ListItemButton>
                        <ListItemButton disabled={exportingEpee} onClick={() => exportMeet("Epee")}>
                            <ListItemText primary="Epee" secondary={exportingEpee ? "Exporting..." : null} />
                        </ListItemButton>
                        <ListItemButton disabled={exportingAll} onClick={() => exportMeet("All")}>
                            <ListItemText primary="All weapons" secondary={exportingAll ? "Exporting..." : null} />
                        </ListItemButton>
                    </List>
                </DialogContent>
                {exportError ? <Alert severity="error">{exportError}</Alert> : null}
            </Dialog>
            <Modal open={Boolean(lineupOpen)} onClose={() => setLineupOpen(null)}>
                <LineupEditor
                    meetId={id}
                    fencers={lineupOpen === BoutSide.Fencer1 ? team1Fencers : team2Fencers}
                    setFencers={lineupOpen === BoutSide.Fencer1 ? setTeam1Fencers : setTeam2Fencers}
                    home={lineupOpen === BoutSide.Fencer1}
                    teamName={(lineupOpen === BoutSide.Fencer1 ? team1Info?.name : team2Info?.name) || "Unknown Team"}
                    teamAbbreviation={(lineupOpen === BoutSide.Fencer1 ? team1Info?.abbreviation : team2Info?.abbreviation) || "UT"}
                    sabreVisible={!openLineup?.Sabre}
                    foilVisible={!openLineup?.Foil}
                    epeeVisible={!openLineup?.Epee}
                    close={() => setLineupOpen(null)}
                />
            </Modal>
            <Modal open={editingDate} onClose={() => setEditingDate(false)}>
                <Box sx={modalBoxStyleNarrower}>
                    <Typography variant="h5" fontFamily="Lexend Deca" sx={{ marginBottom: "20px" }}>
                        Edit date
                    </Typography>
                    <DateTimePicker
                        renderInput={props => <TextField {...props} />}
                        desktopModeMediaQuery="@media only screen and (min-width: 600px)"
                        label="Meet Start Date"
                        value={newDate}
                        onChange={setNewDate}
                    />
                    <Button variant="contained" onClick={submitNewDate} sx={{ margin: "15px 10px 0 0" }}>
                        Confirm
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ marginTop: "15px" }}
                        onClick={() => {
                            setNewDate(dualMeetInfo?.startedAt ? new Date(dualMeetInfo.startedAt) : new Date());
                            setEditingDate(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Modal>
            <Dialog open={configuringRefs} onClose={() => setConfiguringRefs(false)}>
                <DialogTitle>{canManageMeet ? "Edit" : "View"} Referees</DialogTitle>
                <DialogContent sx={{ pt: 0 }}>
                    <Box className="refereeLine">
                        <Typography sx={{ marginTop: "10px", marginRight: "10px" }}>Sabre:</Typography>
                        {configuringSabre ? (
                            <TextField
                                variant="standard"
                                size="small"
                                label="Referee Name"
                                value={refNameSabre}
                                onChange={e => setRefNameSabre(e.target.value)}
                            />
                        ) : (
                            <Typography sx={{ marginTop: "10px" }}>{refNameSabre || "Unset"}</Typography>
                        )}
                        {Boolean(canManageMeet) && (
                            <Button
                                sx={{ margin: "10px 0 0 5px" }}
                                onClick={() => (configuringSabre ? saveReferee("Sabre") : setConfiguringSabre(true))}
                            >
                                {configuringSabre ? "Save" : "Edit"}
                            </Button>
                        )}
                    </Box>
                    <Box className="refereeLine" sx={{ mv: "5px" }}>
                        <Typography sx={{ marginRight: "10px" }}>Foil:</Typography>
                        {configuringFoil ? (
                            <TextField
                                sx={{ marginTop: "-10px" }}
                                variant="standard"
                                size="small"
                                label="Referee Name"
                                value={refNameFoil}
                                onChange={e => setRefNameFoil(e.target.value)}
                            />
                        ) : (
                            <Typography>{refNameFoil || "Unset"}</Typography>
                        )}
                        {Boolean(canManageMeet) && (
                            <Button
                                sx={{ margin: "0 0 0 5px" }}
                                onClick={() => (configuringFoil ? saveReferee("Foil") : setConfiguringFoil(true))}
                            >
                                {configuringFoil ? "Save" : "Edit"}
                            </Button>
                        )}
                    </Box>
                    <Box className="refereeLine">
                        <Typography sx={{ marginRight: "10px" }}>Epee:</Typography>
                        {configuringEpee ? (
                            <TextField
                                sx={{ marginTop: "-10px" }}
                                variant="standard"
                                size="small"
                                label="Referee Name"
                                value={refNameEpee}
                                onChange={e => setRefNameEpee(e.target.value)}
                            />
                        ) : (
                            <Typography>{refNameEpee || "Unset"}</Typography>
                        )}
                        {Boolean(canManageMeet) && (
                            <Button
                                sx={{ margin: "0 0 0 5px" }}
                                onClick={() => (configuringEpee ? saveReferee("Epee") : setConfiguringEpee(true))}
                            >
                                {configuringEpee ? "Save" : "Edit"}
                            </Button>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={choosingSignature} onClose={() => setChoosingSignature(false)}>
                <DialogTitle>Sign Meet</DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    <Tabs value={signingTab} onChange={(_, v) => setSigningTab(v)} variant="scrollable" scrollButtons="auto">
                        <Tab label="Sabre" />
                        <Tab label="Foil" />
                        <Tab label="Epee" />
                    </Tabs>
                    <Box role="tabPanel" hidden={signingTab !== 0}>
                        <List>
                            <ListItemButton onClick={() => startSigning("Sabre")}>
                                <ListItemText>Sabre Referee - {dualMeetInfo?.signatures?.sabreRef ? "Signed" : "Unsigned"}</ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => startSigning("Team A Sabre")}>
                                <ListItemText>
                                    {dualMeetInfo?.team1?.abbreviation || "Team A"} -{" "}
                                    {dualMeetInfo?.signatures?.team1Sabre ? "Signed" : "Unsigned"}
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => startSigning("Team B Sabre")}>
                                <ListItemText>
                                    {dualMeetInfo?.team2?.abbreviation || "Team B"} -{" "}
                                    {dualMeetInfo?.signatures?.team2Sabre ? "Signed" : "Unsigned"}
                                </ListItemText>
                            </ListItemButton>
                        </List>
                    </Box>
                    <Box role="tabPanel" hidden={signingTab !== 1}>
                        <List>
                            <ListItemButton onClick={() => startSigning("Foil")}>
                                <ListItemText>Foil Referee - {dualMeetInfo?.signatures?.foilRef ? "Signed" : "Unsigned"}</ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => startSigning("Team A Foil")}>
                                <ListItemText>
                                    {dualMeetInfo?.team1?.abbreviation || "Team A"} -{" "}
                                    {dualMeetInfo?.signatures?.team1Foil ? "Signed" : "Unsigned"}
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => startSigning("Team B Foil")}>
                                <ListItemText>
                                    {dualMeetInfo?.team2?.abbreviation || "Team B"} -{" "}
                                    {dualMeetInfo?.signatures?.team2Foil ? "Signed" : "Unsigned"}
                                </ListItemText>
                            </ListItemButton>
                        </List>
                    </Box>
                    <Box role="tabPanel" hidden={signingTab !== 2}>
                        <List>
                            <ListItemButton onClick={() => startSigning("Epee")}>
                                <ListItemText>Epee Referee - {dualMeetInfo?.signatures?.epeeRef ? "Signed" : "Unsigned"}</ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => startSigning("Team A Epee")}>
                                <ListItemText>
                                    {dualMeetInfo?.team1?.abbreviation || "Team A"} -{" "}
                                    {dualMeetInfo?.signatures?.team1Epee ? "Signed" : "Unsigned"}
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => startSigning("Team B Epee")}>
                                <ListItemText>
                                    {dualMeetInfo?.team2?.abbreviation || "Team B"} -{" "}
                                    {dualMeetInfo?.signatures?.team2Epee ? "Signed" : "Unsigned"}
                                </ListItemText>
                            </ListItemButton>
                        </List>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog open={signing} onClose={() => setSigning(false)}>
                <DialogTitle>{signatureTitle}</DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    {existingSignature ? (
                        <Typography>
                            This meet has been signed for {signingWeapon} by {existingSignature}. If this is in error, please contact the
                            TbT admins.
                        </Typography>
                    ) : (
                        <>
                            <Typography>
                                Enter your digital signature to sign the meet. Make sure all entered fencers and scores are correct before
                                signing!
                            </Typography>
                            <TextField
                                sx={{ marginTop: "10px" }}
                                variant="outlined"
                                label="Digital Signature"
                                value={signature}
                                onChange={e => setSignature(e.target.value)}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {existingSignature ? (
                        <Button onClick={() => setSigning(false)}>Okay</Button>
                    ) : (
                        <Button disabled={!signature.trim()} onClick={sign}>
                            Sign
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={unlockingMeet} onClose={() => setUnlockingMeet(false)}>
                <DialogTitle>Unlock Dual Meet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Unlocks a dual meet for scoring in case a coach needs to edit the dual meet after it has ended.
                        Normal scorekeeping rules still apply: linked fencers and coaches can score normally, other users require a PIN.
                        The meet will be unlocked for 24 hours.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
                    <Button
                        sx={{ marginRight: "10px" }}
                        variant="text"
                        onClick={() => {
                            setUnlockingMeet(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            unlockMeet();
                            setUnlockingMeet(false);
                        }}
                    >
                        Unlock
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={repairingScore} onClose={() => setRepairingScore(false)}>
                <DialogTitle>Repair meet score</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        In the event that the displayed meet score does not match the score calculated by counting who won each bout, you
                        can try using this tool to repair the displayed meet score. Note that this will not repair any mistakes in
                        scorekeeping.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
                    <Button
                        sx={{ marginRight: "10px" }}
                        variant="text"
                        onClick={() => {
                            setRepairingScore(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            repairScore();
                            setRepairingScore(false);
                        }}
                    >
                        Repair
                    </Button>
                </DialogActions>
            </Dialog>

            <ScoringLockedDialog visible={scoringLockedOpen} close={() => setScoringLockedOpen(false)} />
            <BoutViewerSettingsDialog visible={settingsOpen} close={() => setSettingsOpen(false)} />
            <MedicalDialog
                medicalInfoOpen={medicalInfoOpen}
                setMedicalInfoOpen={setMedicalInfoOpen}
                medicalInfoFencer={medicalInfoFencer}
            />
            {/* <SnackbarError error={snackbarError} /> */}
        </TbTPage>
    );
}
